exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-beauty-fusion-tsx": () => import("./../../../src/pages/industries/beauty/fusion.tsx" /* webpackChunkName: "component---src-pages-industries-beauty-fusion-tsx" */),
  "component---src-pages-industries-beauty-index-tsx": () => import("./../../../src/pages/industries/beauty/index.tsx" /* webpackChunkName: "component---src-pages-industries-beauty-index-tsx" */),
  "component---src-pages-industries-food-fusion-tsx": () => import("./../../../src/pages/industries/food/fusion.tsx" /* webpackChunkName: "component---src-pages-industries-food-fusion-tsx" */),
  "component---src-pages-industries-food-index-tsx": () => import("./../../../src/pages/industries/food/index.tsx" /* webpackChunkName: "component---src-pages-industries-food-index-tsx" */),
  "component---src-pages-industries-healthcare-fusion-tsx": () => import("./../../../src/pages/industries/healthcare/fusion.tsx" /* webpackChunkName: "component---src-pages-industries-healthcare-fusion-tsx" */),
  "component---src-pages-industries-healthcare-index-tsx": () => import("./../../../src/pages/industries/healthcare/index.tsx" /* webpackChunkName: "component---src-pages-industries-healthcare-index-tsx" */),
  "component---src-pages-industries-index-tsx": () => import("./../../../src/pages/industries/index.tsx" /* webpackChunkName: "component---src-pages-industries-index-tsx" */),
  "component---src-pages-industries-real-estate-fusion-tsx": () => import("./../../../src/pages/industries/real-estate/fusion.tsx" /* webpackChunkName: "component---src-pages-industries-real-estate-fusion-tsx" */),
  "component---src-pages-industries-real-estate-index-tsx": () => import("./../../../src/pages/industries/real-estate/index.tsx" /* webpackChunkName: "component---src-pages-industries-real-estate-index-tsx" */),
  "component---src-pages-photocredicts-tsx": () => import("./../../../src/pages/photocredicts.tsx" /* webpackChunkName: "component---src-pages-photocredicts-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-forneture-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/forneture.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-forneture-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-logo-design-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/logo-design.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-logo-design-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-makeup-artist-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/makeup-artist.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-makeup-artist-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-microfrontends-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/microfrontends.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-microfrontends-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-qa-automation-with-ai-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/qa-automation-with-ai.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-qa-automation-with-ai-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-shift-right-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/shift-right.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-shift-right-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-title-one-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/krishnasagar/Desktop/projects/businessmakeoverwebsite/frontend/src/blog/title-one.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-title-one-mdx" */)
}

